<template>
  <div>
    <el-table :data="tableData" border @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary>
      <el-table-column type="selection" width="48" align="center" />
      <el-table-column label="序号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="开票供应商" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.supp_abbr'">
            <el-input v-model="tableData[scope.$index].supp_abbr" disabled maxlength="30" show-word-limit placeholder="暂无供应商简称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购合同号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.podr_no'">
            <el-input v-model="tableData[scope.$index].podr_no" disabled maxlength="30" show-word-limit placeholder="暂无采购合同号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购发票号码" width="200">
        <template v-slot:header>
          <div>
            <span>采购发票号码</span>
            <el-input clearable v-model="searchForm.invoice_no" size="mini" placeholder="请输入"></el-input>
          </div>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.invoice_no'">
            <el-input disabled v-model="tableData[scope.$index].invoice_no" maxlength="30" show-word-limit placeholder="暂无"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_cust_no'">
            <el-input v-model="tableData[scope.$index].prod_cust_no" disabled maxlength="30" show-word-limit placeholder="暂无客户货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column label="我司货号" width="200">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_no'">-->
      <!--            <el-input-->
      <!--              v-model="tableData[scope.$index].prod_no"-->
      <!--              disabled-->
      <!--              maxlength="30"-->
      <!--              show-word-limit-->
      <!--              placeholder="暂无我司货号"-->
      <!--            ></el-input>-->
      <!--          </el-form-item>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="货号后缀" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_poststfix'">
            <el-input v-model="tableData[scope.$index].prod_poststfix" disabled maxlength="30" show-word-limit placeholder="暂无货号后缀"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="总净重" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_tnweight'">
            <el-input
              @input="val => (tableData[scope.$index].prod_tnweight = keep2Decimal(val))"
              v-model="tableData[scope.$index].prod_tnweight"
              @change="tinvInvtotalChange"
              maxlength="9"
              show-word-limit
              placeholder="暂无总净重"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.pinv_part_subtotal'">
            <el-input v-model="tableData[scope.$index].pinv_part_subtotal" disabled maxlength="30" show-word-limit placeholder="暂无金额"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.pinv_part_num'">
            <el-input v-model="tableData[scope.$index].pinv_part_num" disabled maxlength="30" show-word-limit placeholder="暂无数量"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="报关中文名称" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_hsname'">
            <el-input v-model="tableData[scope.$index].prod_hsname" disabled maxlength="30" show-word-limit placeholder="暂无报关中文名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量单位" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_unit'">
            <el-input v-model="tableData[scope.$index].prod_unit" disabled maxlength="30" show-word-limit placeholder="暂无数量单位"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单价" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_price'">
            <el-input v-model="tableData[scope.$index].prod_price" disabled maxlength="30" show-word-limit placeholder="暂无单价"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="增值税率" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_hsinrate'">
            <el-input v-model="tableData[scope.$index].prod_hsinrate" disabled maxlength="30" show-word-limit placeholder="暂无增值税率"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="退税率" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_hsoutrate'">
            <el-input v-model="tableData[scope.$index].prod_hsoutrate" disabled maxlength="30" show-word-limit placeholder="暂无退税率"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="退税额" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_refundtax'">
            <el-input v-model="tableData[scope.$index].prod_refundtax" disabled maxlength="30" show-word-limit placeholder="暂无退税额"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="报关金额" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_usubtotal'">
            <el-input v-model="tableData[scope.$index].prod_usubtotal" disabled maxlength="30" show-word-limit placeholder="暂无报关金额"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="转开票金额" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.tinv_invtotal'">
            <el-input
              @change="tinvInvtotalChange"
              v-model="tableData[scope.$index].tinv_invtotal"
              @input="val => (tableData[scope.$index].tinv_invtotal = keep2Decimal(val))"
              maxlength="13"
              show-word-limit
              placeholder="暂无转开票金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="总毛重" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_tgweight'">
            <el-input v-model="tableData[scope.$index].prod_tgweight" disabled maxlength="30" show-word-limit placeholder="暂无总毛重"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="本币单价" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.tinv_part_rmb'">
            <el-input
              show-word-limit
              maxlength="9"
              size="small"
              @input="val => (tableData[scope.$index].tinv_part_rmb = keep4Decimal(val))"
              v-model="tableData[scope.$index].tinv_part_rmb"
              placeholder="暂无本币单价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="本币金额" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.tinv_part_rmbtotal'">
            <el-input
              show-word-limit
              size="small"
              maxlength="9"
              v-model="tableData[scope.$index].tinv_part_rmbtotal"
              @input="val => (tableData[scope.$index].tinv_part_rmbtotal = keep2Decimal(val))"
              placeholder="暂无本币单价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.pinv_part_remark'">
            <el-input v-model="tableData[scope.$index].pinv_part_remark" disabled maxlength="30" show-word-limit placeholder="暂无备注"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column label="产品描述" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tinv_part_list.' + scope.$index + '.prod_desc'">
            <el-input v-model="tableData[scope.$index].prod_desc" disabled maxlength="20" show-word-limit
              placeholder="暂无产品描述">
              <template slot="append">
                  <el-popover
                    placement="bottom"
                    width="600"
                    @show="textEnlargementShow(0,scope.$index)"
                    trigger="click">
                      <textEnlargement ref="textEnlargement" :disabledFlag="true" @textEnlargementChange="textEnlargementChange" :textEnlargementString="textEnlargementString"></textEnlargement>
                    <span slot="reference"><el-link type="info">...</el-link></span>
                  </el-popover>
                </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';

export default {
  name: 'InvoiceList',
  props: {
    tinvForm: {
      type: Object,
      required: true
    }
  },
  computed: {
    tableData() {
      return this.tinvForm.tinv_part_list.filter(
        x => !this.searchForm.invoice_no || x.invoice_no.toLowerCase().indexOf(this.searchForm.invoice_no.toLowerCase()) > -1
      );
    }
  },
  data() {
    return {
      searchForm: { invoice_no: '' },
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      multiSelection: [],
      custOptionList: []
    };
  },
  components: {
    textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    keep4Decimal,
    keep2Decimal,
    initData() {},
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
      this.$emit('handleSelectionChange', this.multiSelection);
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 41; i++) {
        let temp = '';
        if (i === 0) {
          temp = '合计';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        let totalB = new BigNumber(0);
        let totalC = new BigNumber(0);
        let totalD = new BigNumber(0);
        let totalE = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].pinv_part_num ? datas[i].pinv_part_num : 0);
          totalB = totalB.plus(datas[i].pinv_part_subtotal ? datas[i].pinv_part_subtotal : 0);
          totalC = totalC.plus(datas[i].prod_refundtax ? datas[i].prod_refundtax : 0);
          totalD = totalD.plus(datas[i].prod_tnweight ? datas[i].prod_tnweight : 0);
          totalE = totalE.plus(datas[i].tinv_invtotal ? datas[i].tinv_invtotal : 0);
        }
        totalA = totalA.toNumber();
        totalB = totalB.toNumber();
        totalC = totalC.toNumber();
        totalD = totalD.toNumber();
        totalE = totalE.toNumber();
        totalList[9] = totalA;
        totalList[8] = this.helper.haveFour(totalB);
        totalList[15] = this.helper.haveFour(totalC);
        totalList[7] = this.helper.reservedDigits(totalD);
        totalList[17] = Number(totalE).toFixed(2);
      });
      return totalList;
    },
    // 回显转开票金额
    tinvInvtotalChange() {
      this.$emit('tinvInvtotalChange');
      this.tinvForm.prod_tnweight = this.tinvForm.tinv_part_list.reduce((accumulator, currentValue) => {
        if (accumulator) {
          return Number(accumulator) + Number(currentValue.prod_tnweight);
        } else {
          return Number(currentValue.prod_tnweight);
        }
      }, 0);
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.tableData[val1].pinv_actu_nos;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.tableData[this.textEnlargementNum].pinv_actu_nos = val;
      }
    },
    // // 计算价格保留四位
    calcPrice(num, posi = 0, str) {
      let num1 = 0;
      let fre = null;
      if (posi % 3 === 1) {
        fre = Math.pow(10, posi + 1);
      } else {
        fre = Math.pow(10, posi);
      }
      num1 = (num * fre) / fre;
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.0001;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.0000';
            } else {
              if (value.length - 1 - ind > 4) {
                return value.substr(0, ind + 5);
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    // 计算价格保留2位
    calcPriceT(num, posi = 0, str) {
      let num1 = 0;
      num1 = Number(num);
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.01;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.00';
            } else {
              if (value.length - 1 - ind > 2) {
                return value.substr(0, ind + 3);
              } else if (value.length - 1 - ind < 2) {
                let index = 2 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
