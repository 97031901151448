<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="initData()">
        <el-row>
          <el-col :md="7">
            <el-form-item label="采购合同号:" prop="podr_no">
              <el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请输入采购合同号"> </el-input>
            </el-form-item>
          </el-col>
          <!--          <el-col :md="7">
            <el-form-item label="部件名称:" prop="prod_partno">
              <el-input size="small" v-model.trim="searchForm.prod_partno" clearable placeholder="部件名称"> </el-input>
            </el-form-item>
          </el-col>-->
          <el-col :md="10">
            <el-form-item label="公司抬头:" prop="pinv_cptt_id">
              <el-select v-model="searchForm.pinv_cptt_id" placeholder="请选择公司抬头" size="small" clearable filterable>
                <el-option v-for="item in cpttOptionList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getProdsNow()" class="vg_ml_16">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24" class="vd_dis_right"> </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar10">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
          <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :md="24">
        <el-table
          class="vg_cursor"
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loadFlag"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="出运发票号" prop="pinv_no"></el-table-column> -->
          <el-table-column label="采购发票编码" prop="invoice_no" show-overflow-tooltip></el-table-column>
          <el-table-column label="采购合同号" prop="podr_no">
            <template slot-scope="scope">
              <span v-if="scope.row.podr_no">
                {{ scope.row.podr_no }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="供应商简称" prop="supp_abbr">
            <template slot-scope="scope">
              <span v-if="scope.row.supp_abbr">
                {{ scope.row.supp_abbr }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="公司抬头" prop="pinv_cptt_name">
            <template slot-scope="scope">
              <span v-if="scope.row.pinv_cptt_name">
                {{ scope.row.pinv_cptt_name }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <!--          <el-table-column label="部件名称" prop="prod_partno"></el-table-column>-->
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { pinvAPI } from '@/api/modules/pinv';
import { suppAPI } from '@api/modules/supp';
import pubPagination from '@/components/common/pubPagination';
import { useCpttMixin } from '@/assets/js/mixin/initMixin';
export default {
  name: 'TranList',
  components: {
    pubPagination
  },
  props: {
    dialogTableVisible1: {
      type: Boolean,
      required: true
    },
    tranNo: {
      type: String,
      required: true
    }
  },
  mixins: [useCpttMixin],
  data() {
    return {
      tableData: [],
      searchForm: {
        podr_no: null,
        pinv_cptt_id: null,
        prod_partno: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      prodTypes: [],
      contry: [],
      suppList: []
    };
  },
  created() {},
  watch: {
    dialogTableVisible1: {
      handler(newvalue, oldvalue) {
        if (newvalue) {
          this.initData();
        }
      },
      immediate: true
    }
  },
  filters: {
    // 是否主厂
    formatMainShip(row) {
      if (row === 1) {
        return '是';
      } else if (row === 0) {
        return '否';
      }
    }
  },
  methods: {
    initData() {
      this.loadFlag = true;
      this.getProdsList();
      // this.getSupp()
    },
    getProdsList() {
      get(pinvAPI.getPinvPartByTranNo, {
        tran_no: this.tranNo,
        podr_no: this.searchForm.podr_no,
        pinv_cptt_id: this.searchForm.pinv_cptt_id,
        prod_partno: this.searchForm.prod_partno,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.prod_id;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        podr_no: null,
        pinv_cptt_id: null,
        prod_partno: null
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.clear();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getProdsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getProdsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanelPurchaes');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirmPurchaes', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_dis_right {
  display: flex;
  justify-content: flex-end;
}
</style>
